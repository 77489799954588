<template>
  <div>
    <CRow>
      <CCol sm="12" md="12">
        <CCard>
          <CCardBody>
            <PendingSales />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {
  PendingSales,
} from "../Dashboard";

export default {
  name: "Dashboard",
  components: {
    PendingSales,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
